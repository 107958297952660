import React, { useState, useEffect, useContext } from "react";
import {
  PaymentForm,
  Progress,
  State,
  MethodGroup,
  Confirmation,
  CustomForm,
  WalletAddress,
  Cascading,
} from "./components/Components";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { buildRequest, buildBrowserInfoRequest } from "./utils/RequestHelper";
import {
  shouldAutoPostCheckout,
  showPaymentInstructions,
  handleSelectCard,
  handleDeleteCard,
  redirectToReturnUrl,
} from "./utils/helper";

import "./App.scss";
import { CheckoutContext, DispatchContext } from "./context/CheckoutContext";
import { ThreeDS } from "./components/state/ThreeDS";
import { AlternativeMethods } from "./components/state/AlternativeMethods";
import { GooglePay } from "./components/state/GooglePay";
import { ApplePay } from "./components/state/ApplePay";
import { StaticPages } from "./components/static/StaticPages";

const supportedLocales = [
  "en",
  "ru",
  "it",
  "fr",
  "es",
  "kk",
  "nl",
  "de",
  "ar",
  "pt",
  "lv",
  "ja",
  "hr",
  "el",
  "fi",
  "sv",
  "sk",
  "tr",
  "uz",
  "cs",
  "da",
  "ro",
  "bg",
  "hu",
  "ka",
  "id",
  "kn",
  "bn",
  "te",
  "pa",
  "gu",
  "hi"
];

function App() {
  const form = useForm({
    reValidateMode: "onBlur",
    mode: "onBlur",
    shouldFocusError: false,
  });
  const { id, isLoading, data, method } = useContext(CheckoutContext);
  const {
    startLoading,
    postCheckout,
    postCustomForm,
    postCustomOTPForm,
    postCustomAlternativePMForm,
    setMethod,
    stopLoading,
  } = useContext(DispatchContext);
  const { i18n } = useTranslation();
  const [progressTimeout, setProgressTimeout] = useState(false);
  const [checkInterval, setCheckInterval] = useState(2000);
  const [dataCards, setDataCards] = useState(null);
  const [isNew, setIsNew] = useState(false);
  const [checked, setChecked] = useState(false);
  const [isGooglePayPage, setGooglePayPage] = useState(false);
  const [isApplePayPage, setApplePayPage] = useState(false);

  const handleClick = () => setIsNew(!isNew);
  const handleChange = () => setChecked(!checked);

  useEffect(() => {
    if (data?.cards) {
      if (!data.cards || data.cards.length !== 0) {
        setDataCards(
          data.cards.map((el, idx) => ({ ...el, isSelected: idx === 0 }))
        );
      }
    }
  }, [data?.cards]);

  useEffect(() => {
    setCheckInterval((i) => (i < 32000 ? i * 2 : 32000));
    if (!data) {
      startLoading();
      return;
    }

    if (supportedLocales.indexOf(data.locale) >= 0) {
      i18n.changeLanguage(data.locale);
    } else if (supportedLocales.indexOf(navigator.language) >= 0) {
      i18n.changeLanguage(navigator.language);
    }

    switch (data.state) {
      case "AWAITING_REDIRECT":
        if (data.redirectUrl.htmlType !== 'STATIC') {
          startLoading();
        }
        handleRedirection(data, id);
        break;
      case "CHECKOUT":
        if (shouldAutoPostCheckout(data)) {
          const request = buildBrowserInfoRequest();
          if (data.checkoutOptions[0].paymentMethod !== "BASIC_CARD") {
            postCheckout({
              paymentMethod: data.checkoutOptions[0].paymentMethod,
              ...request,
            });
            break;
          }
          postCheckout(request);
          break;
        }
        if (data.checkoutOptions.length === 1) {
          setMethod(data.checkoutOptions[0]);
        }
        break;
      case "COMPLETED":
      case "DECLINED":
      case "CANCELLED":
        redirectToReturnUrl(
          data.returnUrl,
          data.autoReturnTimeout,
          data.showResultPage
        );
        break;
      case "INPUT_REQUIRED":
      case "AWAITING_CONFIRMATION":
      case "CASCADING_CONFIRMATION":
      case "AWAITING_3DS_CONFIRMATION":
      case "REDIRECT":
        break;
      default:
        startLoading();
        if (data.returnInProgressTimeout) {
          setTimeout(() => {
            if (data.showResultPage) {
              setProgressTimeout(true);
            } else {
              window.location.replace(data.returnUrl);
            }
          }, data.returnInProgressTimeout * 1000);
        }
    }
  }, [data]);

  const handleRedirection = async function (formData, id) {
    const form = document.createElement("form");
    form.action = formData.redirectUrl.url;
    form.method = formData.redirectUrl.redirectMode;

    if (Object.keys(formData.redirectUrl.parameters).length !== 0) {
      Object.entries(formData.redirectUrl.parameters).forEach((param) => {
        const [key, value] = param;
        if (Array.isArray(value)) {
          for (let i = 0; i < value.length; i++) {
            let input = document.createElement("input");
            input.type = "hidden";
            input.name = key;
            input.value = value[i];
            form.appendChild(input);
          }
        } else {
          let input = document.createElement("input");
          input.type = "hidden";
          input.name = key;
          input.value = value;
          form.appendChild(input);
        }
      });

    }

    document.body.appendChild(form);

    await fetch(`/checkouts/${id}/confirm-redirect`, { method: "POST" }).then(
      (response) => {
        if (response.ok && formData.redirectUrl.htmlType === 'DYNAMIC') {
          if (formData.isApplePayOrGooglePayProcessingNeeded) {
            return openPayComponent();
          } else if (formData.redirectUrl.redirectMode === "SIMPLE") {
            window.location.replace(formData.redirectUrl.url);
          } else {
            form.submit();
          }
        } else {
          throw new Error(response.status);
        }
      }
    );
  };

  const openPayComponent = () => {
    stopLoading();
    if (data.isApplePayOrGooglePayProcessingNeeded) {
      if (
        data.isApplePayOrGooglePayProcessingNeeded &&
        data.paymentMethod === "GOOGLEPAYTOKEN"
      ) {
        setGooglePayPage(true);
      } else if (
        data.isApplePayOrGooglePayProcessingNeeded &&
        data.paymentMethod === "APPLEPAYTOKEN"
      ) {
        setApplePayPage(true);
      }
    }
  };

  const onSubmit = (form) => {
    const cardFormData = dataCards && dataCards.find((el) => el.isSelected);
    const _form =
      !isNew && cardFormData
        ? {
            ...cardFormData,
            ...form,
            expiryDate: `${cardFormData.expiryMonth}/${cardFormData.expiryYear.slice(2)}`,
          }
        : form;

    const request = buildRequest(_form, method, data.amount);
    if (
      request.paymentMethod === "BASIC_CARD" &&
      data.tcFooter.text &&
      !checked
    ) {
      return;
    }
    if (request.billingAddressLine1 !== undefined) {
      request.billingAddressLine1 = request.billingAddressLine1.trim();
    }
    if (request.billingAddressLine2 !== undefined) {
      request.billingAddressLine2 = request.billingAddressLine2.trim();
    }

    postCheckout(request);
  };

  const onSubmitCustomForm = (form) => postCustomForm(form);
  const onSubmitCustomOTPForm = (form) => postCustomOTPForm(form);
  const onSubmitCustomAlternativePMForm = (form) =>
    postCustomAlternativePMForm(form);

  function renderContent() {
    if (data) {
      if (progressTimeout) {
        return <State />;
      }
      if (showPaymentInstructions(data)) {
        return <WalletAddress />;
      }
      if (isGooglePayPage) {
        return <GooglePay clearState={setGooglePayPage} />;
      }
      if (isApplePayPage) {
        return <ApplePay clearState={setApplePayPage} />;
      }
      switch (data.state) {
        case "AWAITING_REDIRECT":
          if (data.redirectUrl.htmlType === 'STATIC') {
            return <StaticPages data={data} form={form} />
          }
          break;
        case "CHECKOUT":
          if (!shouldAutoPostCheckout(data)) {
            if (method != null) {
              return (
                <PaymentForm
                  form={form}
                  onSubmit={form.handleSubmit(onSubmit)}
                  dataCards={dataCards}
                  handleSelectCard={(id) =>
                    handleSelectCard(id, dataCards, setDataCards)
                  }
                  handleDeleteCard={(id) =>
                    handleDeleteCard(id, dataCards, setDataCards)
                  }
                  handleClick={handleClick}
                  isNew={isNew}
                  checked={checked}
                  handleChange={handleChange}
                />
              );
            }
            return <MethodGroup amount={data.amount} />;
          }
          break;
        case "COMPLETED":
        case "DECLINED":
        case "CANCELLED":
        case "ERROR":
          return <State />;
        case "CASCADING_CONFIRMATION":
          return <Cascading />;
        case "AWAITING_CONFIRMATION":
          return <Confirmation />;
        case "AWAITING_3DS_CONFIRMATION":
          return (
            <ThreeDS
              form={form}
              onSubmit={form.handleSubmit(onSubmitCustomOTPForm)}
            />
          );
        case "REDIRECT":
          return (
            <AlternativeMethods
              form={form}
              onSubmit={form.handleSubmit(onSubmitCustomAlternativePMForm)}
            />
          );
        case "INPUT_REQUIRED":
          return (
            <CustomForm
              form={form}
              onSubmit={form.handleSubmit(onSubmitCustomForm)}
            />
          );
        default:
          return null;
      }
    }
  }

  return (
    <>
      {data && data.cssUrl && (
        <link rel="stylesheet" type="text/css" href={data.cssUrl} />
      )}
      {isLoading && !showPaymentInstructions(data) ? (
        <Progress checkInterval={checkInterval} />
      ) : (
        <div className="animated-form">
          <main className="container overflow-hidden">{renderContent()}</main>
        </div>
      )}
    </>
  );
}

export default App;

import React, { useEffect, useState } from "react";

import "./style.scss";
import QRCode from "react-qr-code";
import Assets from "../../../assets/Assets";

export const Pay2mUpi = ({ qrData, continueUrl, checkoutUrl }) => {
  const [qrSize, setQrSize] = useState(250)
  const [viewBox, setViewBox] = useState('0 0 256 256')
  const [isBtnDisabled, setIsBtnDisabled] = useState(true)

  const url = window.location.href;
  const parts = url.split('/');
  const id = parts.pop()

  function getQRCodeSize() {
    if (window.innerWidth <= 480) {
      setQrSize(180);
      setViewBox('0 0 180 180')
    }
    else {
      setQrSize(250);
      setViewBox('0 0 256 256')
    }
  }

  useEffect(() => {
    const handleResize = () => {
      getQRCodeSize()
    }
    window.addEventListener("resize", handleResize);
    handleResize()
    startCountdown(15 * 60);
    return () => window.removeEventListener("resize", handleResize);
  }, [])

  const inputField = document.getElementById("utr-input");

  function validateInput() {
    let cleanedValue = inputField.value.replace(/\D/g, '');
    if (inputField.value !== cleanedValue) {
      alert("Only numbers (0-9) are allowed.");
    }

    inputField.value = cleanedValue;

    setIsBtnDisabled(cleanedValue.length !== 12)
  }

  function validateLengthOnBlur() {
    if (inputField.value.length > 0 && inputField.value.length !== 12) {
      alert("UTR must contain exactly 12 digits.");
    }
  }

  function startCountdown(duration) {
    const countdownElement = document.getElementById('countdown');
    const now = Math.floor(Date.now() / 1000);
    let endTime = localStorage.getItem('countdownEndTime' + id);
    if (!endTime) {
      endTime = now + duration;
      localStorage.setItem('countdownEndTime' + id, endTime);
    } else {
      endTime = parseInt(endTime, 10);
    }

    function updateTimer() {
      const remainingTime = endTime - Math.floor(Date.now() / 1000);
      if (remainingTime <= 0) {
        countdownElement.textContent = "Expired";
        clearInterval(interval);
        localStorage.removeItem('countdownEndTime' + id);
        submitPaymentOnTimeout();
      } else {
        const minutes = Math.floor(remainingTime / 60);
        const seconds = remainingTime % 60;
        countdownElement.textContent = `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
      }
    }

    updateTimer();
    const interval = setInterval(updateTimer, 1000);
  }

  function submitPaymentOnTimeout() {
    const paymentData = {};
    fetch(continueUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: JSON.stringify(paymentData),
    }).then(() => {
      window.location.href = checkoutUrl;
    }).catch((error) => {
      console.error("Error:", error);
      window.location.href = checkoutUrl;
    });
  }

  function submitPayment() {
    const utrValue = document.getElementById("utr-input").value;

    if (utrValue.length !== 12) {
      alert("UTR must contain exactly 12 digits.");
      return;
    }

    if (!utrValue.trim()) {
      alert("Please enter the UTR / UPI Txn Id / Bank Ref. no.");
      return;
    }
    const formData = new URLSearchParams();
    formData.append("upiId", utrValue);

    fetch(continueUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: formData.toString(),
    }).then(() => {
      window.location.href = checkoutUrl;
    }).catch((error) => {
      console.error("Error:", error);
      window.location.href = checkoutUrl;
    });
  }

  return (
    <div className="static-page-block">
      <div className="pay2m">
        <div className="container">
          <h3>Scan the QR Code</h3>
          <div id="qrcode">
            <QRCode
              size={qrSize}
              style={{ height: "auto", maxWidth: "100%", width: "100%" }}
              value={qrData}
              viewBox={viewBox}
            />
          </div>
          <p className="details">
            Make sure the details are correct. QR Code is valid for
            <span className="timer" id="countdown">15:00</span> minutes.<br />
            Using any UPI app on your phone
          </p>
          <div className="payment-icons">
            <img src={Assets["copay_brands"]} alt="Paytm" />
          </div>
          <label htmlFor="utr-input"></label><input onInput={validateInput} onBlur={validateLengthOnBlur}
                                                    className="utr-input" id="utr-input" type="text" maxLength="12"
                                                    required
                                                    placeholder="UTR / UPI Txn Id / Bank Ref. no." />
          <p className="details left-aligned">Enter UTR / UPI Txn Id / Bank Ref no. and click 'I have Paid'</p>
          <button onClick={submitPayment} className="pay-btn" id="pay-btn" disabled={isBtnDisabled}>I have Paid</button>
        </div>
      </div>
    </div>
  );
};

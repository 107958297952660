import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import GooglePayButton from "@google-pay/button-react";
import { SecureLogos } from "../Components";
import Assets from "../../assets/Assets";

import "./styles.scss";
import {
  CheckoutContext,
  DispatchContext,
} from "../../context/CheckoutContext";

export const GooglePay = ({ clearState }) => {
  const { data, id } = useContext(CheckoutContext);
  const { cancelPayment } = useContext(DispatchContext);
  const { t } = useTranslation();
  const [paymentRequest, setPaymentRequest] = useState(null);
  const [continueUrl, setContinueUrl] = useState(null);
  const [checkoutUrl, setCheckoutUrl] = useState(null);

  useEffect(() => {
    if (data.applePayOrGooglePayRequestData) {
      const payData = JSON.parse(data.applePayOrGooglePayRequestData);

      const parsedRequest = typeof payData.googlePaymentDataRequest === 'string'
          ? JSON.parse(payData.googlePaymentDataRequest)
          : payData.googlePaymentDataRequest;

      setPaymentRequest(parsedRequest);
      setContinueUrl(payData.continueUrl);
      setCheckoutUrl(payData.checkoutUrl);
    }
  }, [data]);

  const processPayment = (paymentData) => {
    const url = new URL(continueUrl);
    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(paymentData),
    })
      .then((response) => {
        window.location.href = checkoutUrl;
      })
      .catch((error) => {
        console.error("Error:", error);
        window.location.href = checkoutUrl;
      });
  };

  const cancel = () => {
    clearState(false);
    cancelPayment();
  };

  return (
    <>
      {paymentRequest && (
        <>
          <div className="wrapper-state wrapper-pay">
            <div className="navbar">
              <img
                className="inner-form-logo"
                src={Assets["corzapay"]}
                alt="Corzapay"
                style={{ marginTop: "0px" }}
              />
              {data.showCancelButton && (
                <div className="button-cancel" onClick={() => cancel()}>
                  <img src={Assets.close} alt="cancel" />
                </div>
              )}
            </div>
            <div className="state-pay state-pay__info">
              <p className="state-pay__title">Order Id:</p>
              <p className="state-pay__text">{id}</p>
            </div>
            <div className="state-pay state-pay__info">
              <p className="state-pay__title">{t("AmountLabel")}:</p>
              <div className="state-pay__info-item">
                <p className="state-pay__text">
                  {paymentRequest.transactionInfo.currencyCode}
                </p>
                <p className="state-pay__text">
                  {paymentRequest.transactionInfo.totalPrice}
                </p>
              </div>
            </div>

            <div class="state-pay__button">
              <GooglePayButton
                environment="PRODUCTION"
                paymentRequest={paymentRequest}
                onLoadPaymentData={(paymentData) => {
                  processPayment(paymentData);
                }}
                onError={(err) => {
                  if (err.name !== "AbortError") {
                    processPayment(err);
                  }
                }}
              />
            </div>
          </div>
          <SecureLogos />
        </>
      )}
    </>
  );
};

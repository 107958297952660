import React, { useEffect, useState } from "react";

import "./style.scss";
import QRCode from "react-qr-code";

export const InpayQr = ({ qrData }) => {
  const [qrSize, setQrSize] = useState(250)
  const [viewBox, setViewBox] = useState('0 0 256 256')

  function getQRCodeSize() {
    if (window.innerWidth <= 480) {
      setQrSize(180);
      setViewBox('0 0 180 180')
    }
    else {
      setQrSize(250);
      setViewBox('0 0 256 256')
    }
  }

  useEffect(() => {
    const handleResize = () => {
      getQRCodeSize()
    }
    window.addEventListener("resize", handleResize);
    handleResize()
    return () => window.removeEventListener("resize", handleResize);
  }, [])

  return (
    <div className="static-page-block">
      <div className="inpayQr">
        <div className="container">
          <h1>Kode QR yang dihasilkan:</h1>
          <p>Gunakan kode QR ini untuk melanjutkan pembayaran.</p>

          <div id="qrcode">
            <QRCode
              size={qrSize}
              style={{ height: "auto", maxWidth: "100%", width: "100%" }}
              value={qrData}
              viewBox={viewBox}
            />
          </div>

        </div>
      </div>
    </div>
  );
};

import React, { useEffect } from "react";

import "./style.scss";
import Assets from "../../../../assets/Assets";

export const CopayDefault = ({ checkoutUrl, amount, qrData, logoName, transactionId, reference, accountHolderName, redirectUrl, accountNumber, currency }) => {
  useEffect(() => {
    setInterval(checkStatus, 8000);

    return () => clearInterval(checkStatus);
  }, [])

  function copyToClipboard(id) {
    var copyText = document.getElementById(id).innerText;
    if (id === "amount") {
      copyText = copyText.replace(/[^0-9.,]/g, "").trim();
    }
    navigator.clipboard.writeText(copyText).then(() => {
      alert("Đã sao chép: " + copyText);
    });
  }

  function downloadQR() {
    const link = document.createElement('a');
    link.href = qrData;
    link.download = "qr-code.png";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  function checkStatus() {
    fetch(checkoutUrl)
      .then(response => response.json())
      .then(data => {
        if (data.state === 'COMPLETED' || data.state === 'DECLINED' || data.state === 'CANCELLED') {
          window.location.href = redirectUrl;
        }
      })
      .catch(error => console.error('Error:', error));
  }

  function getLogo() {
    if (logoName === 'viettelpay.png') {
      return Assets['viettelpay']
    }
    if (logoName === 'zalopay.png') {
      return Assets['zalopay']
    }
  }

  return (
    <div className="static-page-block">
      <div className="copayDefault">
        <div className="container">
          {logoName !== "empty" && (
            <div className="header">
              <div className="logo">
                <img src={getLogo()} alt="Logo" />
              </div>
            </div>
          )}

          <div className="qr-code">
            <img id="qrCodeImage" alt="QR Code" src={qrData} />
          </div>

          <button className="download-btn" onClick={downloadQR}>
            <img src={Assets["download"]} alt="Download" />
            Tải xuống
          </button>

          <div className="payment-info">
            <h3>Thông tin thanh toán</h3>

            <div className="info-block">
              <span className="info-label">Mã tiền gửi</span>
              <span className="info-value" id="transfer-code">
                {transactionId}
              </span>
              <button
                className="copy-btn"
                onClick={() => copyToClipboard("transfer-code")}
              >
                <img src={Assets["copyStatic"]} alt="Copy" />
                sao chép
              </button>
            </div>

            <div className="info-block">
              <span className="info-label">Tên</span>
              <span className="info-value" id="name">
                {accountHolderName}
              </span>
              <button
                className="copy-btn"
                onClick={() => copyToClipboard("name")}
              >
                <img src={Assets["copyStatic"]} alt="Copy" />
                sao chép
              </button>
            </div>

            <div className="info-block">
              <span className="info-label">Số tài khoản</span>
              <span className="info-value" id="account-number">
                {accountNumber}
              </span>
              <button
                className="copy-btn"
                onClick={() => copyToClipboard("account-number")}
              >
                <img src={Assets["copyStatic"]} alt="Copy" />
                sao chép
              </button>
            </div>

            <div className="info-block">
              <span className="info-label">Số tiền</span>
              <span className="info-value" id="amount">
                {`${amount} ${currency}`}
              </span>
              <button
                className="copy-btn"
                onClick={() => copyToClipboard("amount")}
              >
                <img src={Assets["copyStatic"]} alt="Copy" />
                sao chép
              </button>
            </div>

            <div className="info-block">
              <span className="info-label">Nội dung chuyển khoản</span>
              <span className="info-value" id="transaction-content">
                {reference}
              </span>
              <button
                className="copy-btn"
                onClick={() => copyToClipboard("transaction-content")}
              >
                <img src={Assets["copyStatic"]} alt="Copy" />
                sao chép
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

import React from "react";

import "./style.scss";
import Assets from "../../../../assets/Assets";

export const Biller5Va = ({ accountNumber, bankName }) => {
  const copyToClipboard = () => {
    let tempInput = document.createElement("input");
    tempInput.value = accountNumber;
    document.body.appendChild(tempInput);
    tempInput.select();
    document.execCommand("copy");
    document.body.removeChild(tempInput);
    showToast();
  }
  function showToast() {
    var toast = document.getElementById("toast");
    toast.className = "toast show";
    setTimeout(function () {
      toast.className = "toast";
    }, 3000);
  }

  return (
    <div className="static-page-block">
      <div className="biller5va">
      <div className="container">
        <h1>Nomor Rekening Virtual</h1>
        <div className="account-container">
        						<span id="accountNumber">
                                {accountNumber}
                                </span>
          <span className="copy-icon" onClick={() => copyToClipboard()}>
              <button className="custom-copy-button"
                      onClick={() => copyToClipboard()}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="16"
                     height="16" fill="currentColor">
                <path
                    d="M19,3H9A2,2,0,0,0,7,5V17a2,2,0,0,0,2,2H19a2,2,0,0,0,2-2V5A2,2,0,0,0,19,3Zm0,14H9V5H19ZM5,7H3V19a2,2,0,0,0,2,2H17V19H5Z"></path>
                </svg>
                <span>SALIN</span>
              </button>
            </span>
        </div>
        <h2>{bankName}</h2>
        <div className="instructions">
          Transfer dana ke nomor rekening bank yang tertera di atas. Harap pastikan semua detail sudah benar.
        </div>
      </div>
      <div id="toast" className="toast">Nomor akun berhasil disalin</div>
      </div>
    </div>
  );
};

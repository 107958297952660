import React from "react";

import "./style.scss";
import Assets from "../../../../assets/Assets";

export const InpayVa = ({ accountNumber }) => {

  return (
    <div className="static-page-block">
      <div className="inpayVa">
        <div className="container">
          <h1>Nomor Rekening Virtual</h1>
          <div className="account-number">
            {accountNumber}
          </div>
          <div className="instructions">
            Gunakan nomor rekening virtual ini untuk menyelesaikan pembayaran Anda. Harap pastikan bahwa detailnya benar.
          </div>
        </div>
      </div>
    </div>
  );
};

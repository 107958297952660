import { Biller5Qr } from "./biller5/qr/Biller5Qr";
import { Biller5Va } from "./biller5/va/Biller5Va";
import { InpayQr } from "./inpay/qr/InpayQr";
import { InpayVa } from "./inpay/va/InpayVa";
import { Pay2mUpi } from "./pay2m/Pay2mUpi";
import { CopayUpi } from "./copay/CopayUpi";
import React from "react";
import { CopayDefault } from "./copay/default/CopayDefault";
import { RizonPayRargwallet } from "./rizonpay/RizonPayRargwallet";
import { FormProvider } from "react-hook-form";

const STATIC_PAGES_TYPES = {
  biller5Qr: "BILLER5_QRIS",
  biller5Va: "BILLER5_VA",
  inpayQr: "INPAY_QRIS",
  inpayVa: "INPAY_VA",
  pay2mUpi: "PAY2M_UPI",
  copayUpi: "COPAY_UPI",
  copayDefault: "COPAY_DEFAULT",
  rizonPayRargwallet: "RIZONPAY_RARGWALLET"
}

export const StaticPages = ({ data, form }) => {

  if (data.redirectUrl.staticHtmlParameters.fileName === STATIC_PAGES_TYPES.biller5Qr && data.redirectUrl.staticHtmlParameters.qrData) {
    return <Biller5Qr qrData={data.redirectUrl.staticHtmlParameters.qrData}/>
  }
  if (data.redirectUrl.staticHtmlParameters.fileName === STATIC_PAGES_TYPES.biller5Va) {
    return <Biller5Va bankName={data.redirectUrl.staticHtmlParameters.bankName} accountNumber={data.redirectUrl.staticHtmlParameters.accountNumber}/>
  }
  if (data.redirectUrl.staticHtmlParameters.fileName === STATIC_PAGES_TYPES.inpayQr) {
    return <InpayQr qrData={data.redirectUrl.staticHtmlParameters.qrData}/>
  }
  if (data.redirectUrl.staticHtmlParameters.fileName === STATIC_PAGES_TYPES.inpayVa) {
    return <InpayVa accountNumber={data.redirectUrl.staticHtmlParameters.accountNumber}/>
  }
  if (data.redirectUrl.staticHtmlParameters.fileName === STATIC_PAGES_TYPES.pay2mUpi) {
    return <Pay2mUpi
      qrData={data.redirectUrl.staticHtmlParameters.qrData}
      continueUrl={data.redirectUrl.staticHtmlParameters.continueUrl}
      checkoutUrl={data.redirectUrl.url}
    />
  }
  if (data.redirectUrl.staticHtmlParameters.fileName === STATIC_PAGES_TYPES.copayUpi) {
    return <CopayUpi
      qrData={data.redirectUrl.staticHtmlParameters.qrData}
      continueUrl={data.redirectUrl.staticHtmlParameters.continueUrl}
      checkoutUrl={data.redirectUrl.url}
      amount={data.amount}
      accountNumber={data.redirectUrl.staticHtmlParameters.accountNumber}
    />
  }
  if (data.redirectUrl.staticHtmlParameters.fileName === STATIC_PAGES_TYPES.copayDefault) {
    return <CopayDefault
      qrData={data.redirectUrl.staticHtmlParameters.qrData}
      checkoutUrl={data.redirectUrl.staticHtmlParameters.backendCheckoutUrl}
      redirectUrl={data.redirectUrl.url}
      amount={data.amount}
      accountHolderName={data.redirectUrl.staticHtmlParameters.accountHolderName}
      reference={data.redirectUrl.staticHtmlParameters.reference}
      transactionId={data.redirectUrl.staticHtmlParameters.transactionId}
      logoName={data.redirectUrl.staticHtmlParameters.logoName}
      accountNumber={data.redirectUrl.staticHtmlParameters.accountNumber}
      currency={data.currency}
    />
  }
  if (data.redirectUrl.staticHtmlParameters.fileName === STATIC_PAGES_TYPES.rizonPayRargwallet) {
    return <FormProvider {...form}>
    <RizonPayRargwallet
      continueUrl={data.redirectUrl.staticHtmlParameters.continueUrl}
      checkoutUrl={data.redirectUrl.url}
      amount={data.amount}
      accountNumber={data.redirectUrl.staticHtmlParameters.accountNumber}
      currency={data.currency}
      form={form}
    />
  </FormProvider>
  }
};

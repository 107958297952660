import React, { useEffect, useState } from "react";
import QRCode from "react-qr-code";

import "./style.scss";
import Assets from "../../../../assets/Assets";

export const Biller5Qr = ({ qrData }) => {
  const [qrSize, setQrSize] = useState(250)
  const [viewBox, setViewBox] = useState('0 0 256 256')

  function getQRCodeSize() {
    if (window.innerWidth <= 480) {
      setQrSize(180);
      setViewBox('0 0 180 180')
    }
    else {
      setQrSize(250);
      setViewBox('0 0 256 256')
    }
  }

  useEffect(() => {
    const handleResize = () => {
      getQRCodeSize()
    }
    window.addEventListener("resize", handleResize);
    handleResize()
    return () => window.removeEventListener("resize", handleResize);
  }, [])

  setTimeout(() => {
    let qelem = document.querySelector('#qrcode svg')
    let dlink = document.querySelector('#qrcode_download')
    const svgXML = new XMLSerializer().serializeToString(qelem);
    const dataUrl = "data:image/svg," + encodeURIComponent(svgXML);
    dlink.setAttribute('href', dataUrl);
    dlink.setAttribute('download', 'qr.svg');
    dlink.removeAttribute('hidden');
  }, 500);

  return (
    <div className="static-page-block">
      <div className="biller5qr">
        <div className="container">
          <h2>Kode QR yang dihasilkan:</h2>
          <div id="qrcode">
            <QRCode
              size={qrSize}
              style={{ height: "auto", maxWidth: "100%", width: "100%" }}
              value={qrData}
              viewBox={viewBox}
            />
          </div>
          <a id='qrcode_download'>
            <div className="button_download_container">
              <img id="download_img" alt="download_icon"
                  src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNTAiIGhlaWdodD0iNTAiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiBzdHJva2U9IiNBMTkyNzIiIHN0cm9rZS13aWR0aD0iMS41Ij4NCiAgPHBhdGggZD0iTTE0IDJINkM0Ljg5NTQzIDIgNCAyLjg5NTQzIDQgNFYyMEM0IDIxLjEwNDYgNC44OTU0MyAyMiA2IDIySDE4QzE5LjEwNDYgMjIgMjAgMjEuMTA0NiAyMCAyMFY4TDE0IDJaIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4NCiAgPHBhdGggZD0iTTE0IDJWOEgyMCIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+DQogIDxwYXRoIGQ9Ik0xMiAxOFYxMiIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+DQogIDxwYXRoIGQ9Ik05IDE1TDEyIDE4TDE1IDE1IiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4NCjwvc3ZnPg0K" />
              <div>UNDUH</div>
            </div>
          </a>
          <div className="instructions">
            Pindai Kode QR ini untuk menyelesaikan pembayaran Anda. Pastikan detailnya benar.
          </div>
        </div>
      </div>
    </div>
  );
};

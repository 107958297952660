import React, { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import "./style.scss";
import Assets from "../../../assets/Assets";
import { CustomPhoneInput } from "../../../components/input/phoneInput/CustomPhoneInput";
import { ReactComponent as PhoneIcon } from "../../../assets/img/form-icons/bi_phone-fill.svg";
import { useTranslation } from "react-i18next";

export const RizonPayRargwallet = ({ continueUrl, checkoutUrl, amount, accountNumber, currency, form }) => {

  const { errors } = useFormContext();
  const { t } = useTranslation();

  function submitPayment() {
    const utrValue = document.getElementById("utr-input").value;

    if (!utrValue.trim()) {
      alert("Please enter the UTR / UPI Txn Id / Bank Ref. no.");
      return;
    }
    const formData = new URLSearchParams();
    formData.append("phoneNumber", utrValue);

    fetch(continueUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: formData.toString(),
    }).then(() => {
      window.location.href = checkoutUrl;
    }).catch((error) => {
      console.error("Error:", error);
      window.location.href = checkoutUrl;
    });
  }

  function copyText(elementId) {
    const text = document.getElementById(elementId).textContent;
    navigator.clipboard.writeText(text);
  }

  return (
    <div className="static-page-block">
      <form onSubmit={form.handleSubmit(submitPayment)} className="rizonpay-rargwallet">
        <div className="container">
          <p className="details">
            {t("rizonPayInstructions")}
          </p>
          <div className="payment-box">
            <p><strong>{t("rizonPayPay")}</strong><span id="amount">{`${amount} ${currency}`}</span>
              <button type="button" className="copy-btn" onClick={() => copyText('amount')}>
                <img src={Assets["copyStatic"]} alt="Copy" />
              </button>
            </p>
            <p><strong>{t("rizonPayTo")}</strong> <span id="upi-id">{ accountNumber }</span>
              <button type="button" className="copy-btn" onClick={() => copyText('upi-id')}>
                <img src={Assets["copyStatic"]} alt="Copy" />
              </button>
            </p>
          </div>
          <p className="details left-aligned">{t("rizonPayInputInstructions")}</p>
          <label htmlFor="utr-input"></label>
          <CustomPhoneInput
                id="utr-input"
                name="utr-input"
                label="customerPhoneLabel"
                required="true"
                additionalChild={
                  <PhoneIcon
                    className={`left-slot ${errors["utr-input"]?.message ? "error" : ""}`}
                  />
                }
              />
          {/* <input
            className="utr-input"
            id="utr-input"
            type="text"
            maxLength="12"
            required
            placeholder="Phone Number with Country Code"
            onInput={validateInput}
          /> */}
          <p className="details left-aligned">{t("rizonPaySubmitLabel")}</p>
          <button
            type="submit"
            className="pay-btn"
            id="pay-btn"
          >{t("rizonPaySubmit")}</button>
        </div>
      </form>
    </div>
  );
};
